
// INIT AOS
jQuery(document).ready(function($) {

  AOS.init({
		duration: 1200,
    once: true,
    disable: 'mobile'
	});

  //IE OBJECT FIT FIX
  objectFitImages();

  //HIDE AND SHOW MENU ON SCROLL
  var lastScrollTop = 0;
  $(window).scroll(function(event){
     var st = $(this).scrollTop();
     if (st > lastScrollTop){
       $('header.main').addClass('fadeDown');
       $('header.main').addClass('whiteBack');
      }
      else {
       $('header.main').removeClass('fadeDown');
     }
     if(st == 0) {
       $('header.main').removeClass('whiteBack');
     }
     lastScrollTop = st;
  });

});

// TOGGLE SEARCH
jQuery(document).ready(function($) {
  $('.search-button').on('click', function(){
    $('div.search').toggle();
  });
});

// SHOW FACTS
jQuery(document).ready(function($) {
  $('.facts figure').on('click', function(){
    $(this).toggleClass("expand");
    $(this).closest('.facts').find('article').toggleClass("expand");
  });
});

// TOGGLE NEWSLETTER
jQuery(document).ready(function($) {
  $('a.news-letter').on('click', function(){
    $('section.news-letter').toggle();
  });
});

//TOGGLE MENU
jQuery(document).ready(function($) {
  $('.burger').on('click', function(){
    $('body').toggleClass("nav-open");
  });
});

// HEADER FADE IN ON SCROLL
jQuery(document).ready(function($) {
    $(window).scroll( function(){

        $('.fadein').each( function(i){
            var bottom_of_object = $(this).position().top + $(this).outerHeight();
            var bottom_of_window = $(window).scrollTop() + $(window).height();

            if( bottom_of_window > bottom_of_object ){
                $(this).animate({'opacity':'1'},1000);

            }
        });
    });
});


// OFFSET MAIN BASED ON MENY HEIGHT
jQuery(document).ready(function($) {
  if (!/Mobi|Android/i.test(navigator.userAgent)) {
    var offset = $('header.main').height() + parseFloat( $('header.main').css('padding-top') ) + parseFloat( $('header.main').css('margin-bottom') ) + "px";
    $('body.home main.main').css('padding-top', offset);
    $('body.archive main.main').css('padding-top', offset);
    $('body.page main.main').css('padding-top', offset);
    $('article.category-video').parent('main.main').css('padding-top', offset);
    $('body.single-jobb main.main').css('padding-top', offset);
    $('body.error404 main.main').css('padding-top', offset);
    $('body.search main.main').css('padding-top', offset);
  }
});

//COLOR HOVERS
  jQuery(document).ready(function($){
        var i = 0;
        $( ".archive-list" )
        .mouseover(function() {
          $(this).find('.desaturate').removeClass('desaturate').addClass("saturate");
          $(this).find('.hr-black').removeClass('hr-black').addClass("hr-yellow");
        })
        .mouseout(function() {
          $(this).find('.saturate').removeClass('saturate').addClass("desaturate");
           $(this).find('.hr-yellow').removeClass('hr-yellow').addClass("hr-black");
        });
      });

//MENU
jQuery(document).ready(function($){
  //if you change this breakpoint in the style.css file (or _layout.scss if you use SASS), don't forget to update this value as well
  var MqL = 1170;
  //move nav element position according to window width
  moveNavigation();
  $(window).on('resize', function(){
    (!window.requestAnimationFrame) ? setTimeout(moveNavigation, 300) : window.requestAnimationFrame(moveNavigation); // jshint ignore:line
  });

  //mobile - open lateral menu clicking on the menu icon
  $('.cd-nav-trigger').on('click', function(event){
    event.preventDefault();
    if( $('.cd-main-content').hasClass('nav-is-visible') ) {
      closeNav();
      $('.cd-overlay').removeClass('is-visible');
    } else {
      $(this).addClass('nav-is-visible');
      $('.cd-primary-nav').addClass('nav-is-visible');
      $('.cd-main-header').addClass('nav-is-visible');
      $('.cd-main-content').addClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
        $('body').addClass('overflow-hidden');
      });
      toggleSearch('close');
      $('.cd-overlay').addClass('is-visible');
    }
  });

  //open search form
  $('.cd-search-trigger').on('click', function(event){
    event.preventDefault();
    toggleSearch();
    closeNav();
  });

  //close lateral menu on mobile
  $('.cd-overlay').on('swiperight', function(){
    if($('.cd-primary-nav').hasClass('nav-is-visible')) {
      closeNav();
      $('.cd-overlay').removeClass('is-visible');
    }
  });
  $('.nav-on-left .cd-overlay').on('swipeleft', function(){
    if($('.cd-primary-nav').hasClass('nav-is-visible')) {
      closeNav();
      $('.cd-overlay').removeClass('is-visible');
    }
  });
  $('.cd-overlay').on('click', function(){
    closeNav();
    toggleSearch('close');
    $('.cd-overlay').removeClass('is-visible');
  });


  //prevent default clicking on direct children of .cd-primary-nav
  $('.cd-primary-nav').children('.has-children').children('a').on('click', function(event){
    event.preventDefault();
  });
  //open submenu
  $('.has-children').children('a').on('click', function(event){
    if( !checkWindowWidth() ) event.preventDefault(); // jshint ignore:line
    var selected = $(this);
    if( selected.next('ul').hasClass('is-hidden') ) {
      //desktop version only
      selected.addClass('selected').next('ul').removeClass('is-hidden').end().parent('.has-children').parent('ul').addClass('moves-out');
      selected.parent('.has-children').siblings('.has-children').children('ul').addClass('is-hidden').end().children('a').removeClass('selected');
      $('.cd-overlay').addClass('is-visible');
    } else {
      selected.removeClass('selected').next('ul').addClass('is-hidden').end().parent('.has-children').parent('ul').removeClass('moves-out');
      $('.cd-overlay').removeClass('is-visible');
    }
    toggleSearch('close');
  });

  //submenu items - go back link
  $('.go-back').on('click', function(){
    $(this).parent('ul').addClass('is-hidden').parent('.has-children').parent('ul').removeClass('moves-out');
  });

  function closeNav() {
    $('.cd-nav-trigger').removeClass('nav-is-visible');
    $('.cd-main-header').removeClass('nav-is-visible');
    $('.cd-primary-nav').removeClass('nav-is-visible');
    $('.has-children ul').addClass('is-hidden');
    $('.has-children a').removeClass('selected');
    $('.moves-out').removeClass('moves-out');
    $('.cd-main-content').removeClass('nav-is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
      $('body').removeClass('overflow-hidden');
    });
  }

  function toggleSearch(type) {
    if(type==="close") {
      //close serach
      $('.cd-search').removeClass('is-visible');
      $('.cd-search-trigger').removeClass('search-is-visible');
      $('.cd-overlay').removeClass('search-is-visible');
    } else {
      //toggle search visibility
      $('.cd-search').toggleClass('is-visible');
      $('.cd-search-trigger').toggleClass('search-is-visible');
      $('.cd-overlay').toggleClass('search-is-visible');
      if($(window).width() > MqL && $('.cd-search').hasClass('is-visible')) $('.cd-search').find('input[type="search"]').focus(); // jshint ignore:line
      ($('.cd-search').hasClass('is-visible')) ? $('.cd-overlay').addClass('is-visible') : $('.cd-overlay').removeClass('is-visible') ; // jshint ignore:line
    }
  }

  function checkWindowWidth() {
    //check window width (scrollbar included)
    var e = window,
            a = 'inner';
        if (!('innerWidth' in window )) {
            a = 'client';
            e = document.documentElement || document.body;
        }
        if ( e[ a+'Width' ] >= MqL ) {
      return true;
    } else {
      return false;
    }
  }

  function moveNavigation(){ // jshint ignore:line
    var navigation = $('.cd-nav');
      var desktop = checkWindowWidth();
        if ( desktop ) {
      navigation.detach();
      navigation.insertBefore('.cd-header-buttons');
    } else {
      navigation.detach();
      navigation.insertAfter('.cd-main-content');
    }
  }
});
